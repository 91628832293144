<template>
    <div style="padding-bottom:50px;">
        <div  style="position:fixed;right: 10px;bottom: 10px;z-index: 2;" >
            <div style="border: 2px solid rgba(226, 186, 127, .7);border-radius: 50%;">
                <van-button style="background: rgba(226, 186, 127, .7);padding: 10px;height: 70px;margin: 1px;" round @click.stop="$router.push('/lottery/mine')" >
                    <van-icon name="user-o" color="#fff" size="30"/>
                    <p style="margin: 0;color: #fff;font-size: 12px;">我的订单</p>
                </van-button>
            </div>
        </div>
<!--        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">-->
<!--            <van-swipe-item @click="adv">-->
<!--                <img src="http://lf-public.oss-cn-shenzhen.aliyuncs.com/lottery/adv/modou.png" alt="" style="width: 100%;">-->
<!--            </van-swipe-item>-->
<!--            <van-swipe-item @click="$router.push('/lottery/mine')">-->
<!--                <img src="http://lf-public.oss-cn-shenzhen. aliyuncs.com/lottery/title.jpg" alt="" style="width: 100%;">-->
<!--            </van-swipe-item>-->
<!--        </van-swipe>-->
<!--        <div @click="$router.push('/lottery/mine')">-->
<!--            <img src="http://lf-public.oss-cn-shenzhen.aliyuncs.com/lottery/title.jpg" alt="" style="width: 100%;">-->
<!--        </div>-->

        <div @click="adv">
            <img src="http://lf-public.oss-cn-shenzhen.aliyuncs.com/lottery/adv/modou.png" alt="" style="width: 100%;">
        </div>

        <van-dropdown-menu class="dropdown_menu">
            <van-dropdown-item v-model="value1" :options="option1" />
            <van-dropdown-item v-model="value2" :options="option2" />
            <van-dropdown-item v-model="value3" :options="option3" />
        </van-dropdown-menu>
<!--        <div style="margin: 10px 0 15px 10px;">-->
<!--            <span @click="$router.push('/lottery/mine')" style="color: #337ab7;line-height: 29px;">我的参与记录>></span>-->
<!--            <van-tag style="float: right;border-radius: 999px 0 0 999px;padding:5px 10px 5px 15px;"-->
<!--                     @click="$router.push('/lottery/rule')" size="large">活动规则-->
<!--            </van-tag>-->
<!--        </div>-->
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" id="lottery_page" style="background: #f7f8fa;padding-bottom: 50px;">
            <van-notice-bar mode="closeable" wrapable :scrollable="false" v-if="errMsg" :text="errMsg"/>
            <van-list v-model="loading" :finished="finished" finished-text="" @load="loadData" offset="100">
                <van-empty v-if="loadSuccess && !list.length"  description="活动已经被抢光啦，请持续关注" />
                <van-card
                        style="margin-top: 8px;"
                        v-for="(item,index) in list" :key="index"
                        :thumb="item.title_img"
                        :origin-price="item.price"
                        :desc="item.shop_name"
                        @click="$router.push({ path: '/lottery/detail', query: { act_id: item.act_id }})">
                    <template #title>
                        <div class="van-card__desc van-multi-ellipsis--l2"
                             style="font-size: 15px;line-height:20px;max-height: 40px;font-weight: bold;">
                            <van-tag plain type="primary" size="mini">{{item.shop_type_name}}</van-tag>
                            {{item.goods_name}}
                        </div>
                    </template>
<!--                    <template #price>-->
<!--                        <div>-->
<!--                            <span class="van-card__price-currency">¥</span>-->
<!--                            <span class="van-card__price-integer" style="margin: 0 5px 0 2px;">0</span>-->
<!--                        </div>-->
<!--                    </template>-->
                    <template #tag>
                        <van-tag mark :type="item.text.type">{{item.text.status}}</van-tag>
                    </template>
                    <template #tags>
                        <span>{{item.short_name}}</span>
                    </template>
                    <template #num>
                    <span v-if="item.status === 0" class="draw_time"
                          style="font-size: 12px;">{{item.end_time}}截止</span>
                    </template>
                    <template #footer>
                        <div style="margin: 10px 0 5px;" v-if="item.status === 0">
                            <van-progress color="#e2ba7f" :percentage="item.percent" :show-pivot="false"/>
                        </div>
                        <div v-if="item.status === 0" style="color: #aaa;font-size: 14px;text-align: right;">
                            <span style="font-size: 14px;color: #e2ba7f;font-weight: bold;">{{item.count}}</span>/{{item.members}}人参与&nbsp;或&nbsp;
                            <van-count-down millisecond
                                            :time="new Date(item.end_time.replace(/-/g, '/')).getTime() - new Date().getTime()"
                                            format="HH:mm:ss"
                                            style="font-size: 14px;color: #e2ba7f;font-weight: bold;display: inline;"
                                            @finish="list.splice(index, 1)"/>
                            <span>后截止</span>
                        </div>
                    </template>
                </van-card>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
    import {Dialog, Toast} from "vant";
    import {mapState} from 'vuex';

    export default {
        name: "Lottery",
        computed:{
            ...mapState(['common']),
        },
        data() {
            return {
                loadSuccess: false,
                refreshing: false,
                list: [],
                progressing: false,
                interval: null,
                errMsg:'',
                talent:{},
                init:false,

                loading: false,
                finished: false,
                pageSize:10,
                pageNo:1,

                value1: 0,
                value2: 0,
                value3: 0,
                option1: [
                    { text: '所有团购', value: 0 },
                    { text: '可参与', value: 1 },
                    { text: '已参与', value: 2 },
                ],
                option2: [
                    { text: '全市', value: 0 },
                ],
                option3: [
                    { text: '所有分类', value: 0 },
                ],
                container: null,
            }
        },
        watch:{
            value1() {
                this.onRefresh();
            },
            value2() {
                this.onRefresh();
            },
            value3() {
                this.onRefresh();
            },
        },
        async created() {
            let that = this;
            // 等待初始化完成
            await this.talentMessage();
            // 如果有错误信息，不允许进行后续操作
            // if(this.errMsg){
                // await Dialog.alert({message: this.errMsg});
                // this.loadSuccess = true;
                // this.init = true;
                // return;
            // }
            // 初始化页面的时候，初始化一个定时器，每秒更新当前进度条
            that.interval = setInterval(function () {
                let now = new Date().getTime();
                for (let i = 0; i < that.list.length; i++) {
                    let percent = (now - that.list[i]._start) / (that.list[i]._end - that.list[i]._start) * 100;
                    if (percent <= 100) {
                        that.list[i].percent = percent;
                    }
                }
            }, 1000);
            this.init = true;
        },
        destroyed() {
            // 关闭页面的时候清除这个定时器
            clearInterval(this.interval);
        },
        mounted() {
            this.container = this.$refs.container;
        },
        methods: {
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.refreshing = true;
                this.loading = true;
                this.pageNo = 1;
                this.loadData();
            },
            initSuccess(){
                let that = this;
                return new Promise((resolve, reject) => {
                    setInterval(function(){
                        if(that.init) resolve();
                    },100);
                })
            },
            async loadData() {
                let that = this;
                if(!this.init){
                    await this.initSuccess();
                    // if (this.errMsg) {
                    //     this.finished = true;
                    //     this.loading = false;
                    //     this.loadSuccess = true;
                    //     return;
                    // }
                }
                this.post('/api/client/activity/talent/list', {
                    pageSize: that.pageSize,
                    pageNo: that.pageNo,
                    city: that.talent.city,
                    participate:that.value1,
                    district:that.value2,
                    type:that.value3,
                }).then(result => {
                    if (result.code === 0) {
                        // 刷新
                        if (this.refreshing) {
                            this.list = [];
                            this.refreshing = false;
                        }
                        for (let i = 0; i < result.data.length; i++) {
                            result.data[i]._start = new Date(result.data[i].start_time.replace(/-/g, "/")).getTime();
                            result.data[i]._end = new Date(result.data[i].end_time.replace(/-/g, "/")).getTime();
                            result.data[i].percent = (new Date().getTime() - result.data[i]._start) / (result.data[i]._end - result.data[i]._start) * 100;
                            this.list.push(result.data[i]);
                        }
                        that.pageNo = that.pageNo + 1;
                        this.finished = result.data.length < this.pageSize;
                        this.loading = false;
                    } else {
                        Toast(result.msg);
                    }
                    this.loadSuccess = true;
                });
            },
            talentMessage() {
                let that = this;
                return new Promise((resolve, reject) => {
                    that.post('/api/client/activity/talent/list/before',{adcode:that.common.address.adcode}).then(result => {
                        if(result.code === 0){
                            this.talent = result.data.talent;
                            this.option2 = result.data.districts;
                            this.option2.unshift({ text: this.talent.city_name, value: 0 });
                            this.option3 = result.data.type;
                            this.option3.unshift({ text: '所有分类', value: 0 });
                            this.errMsg = result.msg;
                        } else {
                            this.errMsg = result.msg;
                        }
                        resolve();
                    }).catch(e=>{
                        console.log(e);
                        this.errMsg = '查询失败，请稍后再试';
                        resolve();
                    });
                })
            },
            adv(){
                location.href = 'https://mp.weixin.qq.com/s/aZOGG4PH6nagdGLrk-58yg';
            },
        }
    }
</script>

<style scoped>
    .draw_time {
        float: right;
    }

    .lottery_card_footer {
        display: flex;
        text-align: left;
    }

    .lottery_card_footer > div {
        flex: 1 0 auto;
        line-height: 20px;
        margin-top: 5px;
    }

    .lottery_card_footer > div:nth-child(2) {
        text-align: right;
    }
</style>
<style>
    #lottery_page .van-card__origin-price {
        font-size: 14px;
    }

    #lottery_page .van-card__num {
        font-size: 14px;
    }
    .dropdown_menu>div:first-child{
        height: 40px;
    }
</style>